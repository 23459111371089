<template>
  <!--
    Displays the devices common information.
  -->
  <table class="defaultTable deviceCommontable">
    <tbody>
      <tr>
        <th 
          class="font-weight-bold text-right" 
          style="width:150px;"
        >
          {{ $t('deviceDetailTableComponent.status') }}
        </th>
        <td
          class="altCell"
          colspan="2"
        >
          <span :class="device.vpnIpAddress ? 'green' : 'red'">
            <font-awesome-icon
              class="mr-1"
              :icon="device.vpnIpAddress ? 'plug' : 'ban'"
            />
            {{ device.vpnIpAddress ? 'Available' : 'Unavailable' }}
          </span>
        </td>
      </tr>

      <tr v-if="device.vpnIpAddress">
        <th class="font-weight-bold text-right">
          VPN {{ $t('deviceDetailTableComponent.ipAddress') }}
        </th>
        <td colspan="2">
          <CopyToClipboard :copy-data="device.vpnIpAddress" />
        </td>
      </tr>

      <tr v-if="device.localIp">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.ipAddress') }}
        </th>
        <td colspan="2">
          <CopyToClipboard :copy-data="device.localIp" />
        </td>
      </tr>

      <tr v-if="!authenticationHasRole('zerolevel')">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.password') }}
        </th>
        <td colspan="2">
          <PasswordViewer
            v-if="device.password"
            :password="device.password"
          />
        </td>
      </tr>
      <template v-if="isVnc && !authenticationHasRole('zerolevel')">
        <LoadingPlaceholder v-if="terminalLoading" />
        <template v-else>
          <tr>
            <th class="font-weight-bold text-right">
              VNC {{ $t('deviceDetailTableComponent.password') }}
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.vncPassword"
                :password="terminal.vncPassword"
                @sawPw="createViewAuditLow()"
              />
            </td>
          </tr>
          <tr>
            <th class="font-weight-bold text-right">
              VNC {{ $t('deviceDetailTableComponent.password') }} view only
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.vncViewPassword"
                :password="terminal.vncViewPassword"
                @sawPw="createViewAuditLow()"
              />
            </td>
          </tr>
        </template>
      </template>
      <template v-if="isTerminal && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))">
        <LoadingPlaceholder v-if="terminalLoading" />
        <template v-else>
          <tr>
            <th class="font-weight-bold text-right">
              Reseller {{ $t('username') }}
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.username"
                :password="terminal.username"
              />
            </td>
          </tr>
          <tr>
            <th class="font-weight-bold text-right">
              Reseller {{ $t('deviceDetailTableComponent.password') }}
            </th>
            <td colspan="2">
              <PasswordViewer
                v-if="terminal && terminal.password"
                :password="terminal.password"
              />
            </td>
          </tr>
        </template>        
      </template>
      <tr v-if="restartAppCommand && activeDevice">
        <td>{{ restartAppCommand.name }}</td>
        <td>
          <button
            class="btn btn-primary"
            @click="executeCommand(restartAppCommand, true)"
          >
            <font-awesome-icon
              class="mr-2"
              icon="terminal"
            />
            {{ $t('execute') }}
          </button>
        </td>
        <td>
          <LoadingPlaceholder v-if="restartAppExecuting" />
          <pre
            v-else-if="restartAppResponse"
            class="p-4 pre"
          >{{ restartAppResponse }}</pre>
          <template v-else>
            {{ $t('noDataAvailable') }}
          </template>
        </td>
      </tr>
      <tr v-if="injectTestCardCommand && activeDevice">
        <td>{{ injectTestCardCommand.name }}</td>
        <td>
          <button
            class="btn btn-primary"
            @click="executeCommand(injectTestCardCommand, false)"
          >
            <font-awesome-icon
              class="mr-2"
              icon="terminal"
            />
            {{ $t('execute') }}
          </button>
        </td>
        <td>
          <LoadingPlaceholder v-if="injectTestCardExecuting" />
          <pre
            v-else-if="injectTestCardResponse"
            class="border p-4 pre"
          >{{ injectTestCardResponse }}</pre>
          <template v-else>
            {{ $t('noDataAvailable') }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "DeviceDetailCommonTable",
  components: {
    CopyToClipboard: () => import('@/components/CopyToClipboard.vue'),
    PasswordViewer: () => import('@/components/PasswordViewer.vue')
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    device: {
      type: Object,
      required: true
    },
    isTerminal: {
      type: Boolean,
      default () {
        return false;
      }
    },
    installationId: {
      type: String,
      default () {
        return null;
      }
    },
    isVnc: {
      type: Boolean,
      default () {
        return false;
      }
    }
  },
  data () {
    return {
      restartAppCommand: null,
      injectTestCardCommand: null,
      restartAppExecuting: false,
      injectTestCardExecuting: false,
      restartAppResponse: null,
      injectTestCardResponse: null,
      terminalLoading: false,
      terminal: null
    }
  },
  computed: {
    activeDevice: function () {
      if (this.device && this.device.vpnIpAddress) {
        return true;
      }
      return false;
    }
  },
  created () {
    this.restartAppCommand = this.device.commands.find(x => { return x.name == 'restartApp' });
    this.injectTestCardCommand = this.device.commands.find(x => { return x.name == 'injectTestCard' });
    if(this.isTerminal || this.isVnc) {
      this.getTerminalCredentials();
    }
  },
  methods: {
  createViewAuditLow () {
    this.axios.get(`/CentralDeviceManagement/AddVncViewAudit?deviceId=${this.device.uuid}&installationId=${this.installationId}`).then(() => {this.$emit("reloadAuditLogs");});
  },
  getTerminalCredentials () {
    this.terminalLoading = true;
    this.axios.get(`/CentralDeviceManagement/GetTerminalCredentials?deviceUuid=${this.device.uuid}&configurationId=${this.device.activeDeviceConfigurationId}&vnc=${this.isVnc}`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.terminal = response.data; 
      })
      .finally(() => { this.terminalLoading = false;})
    },
   executeCommand (command, isRestart) {
      if(isRestart) {
        this.restartAppExecuting = true;
      } 
      else 
      {
        this.injectTestCardExecuting = true;
      }
      
      command.lastRequest = Date.now();
      this.$nextTick(() => {
        window.$('[data-toggle="tooltip"]').tooltip();
      });
      this.response = null;
      let commandRequest = {
        'Command': command.name,
        'Args': ''
      };
      this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.device.uuid }`, commandRequest)
        .then((response) => {
          if (response && response.data && response.data.output) {
            if(isRestart) {
              this.restartAppResponse = response.data.output;
            } 
            else 
            {
              this.injectTestCardResponse = response.data.output;
            }
          }
        })
        .finally(() => {
          if(isRestart) {
            this.restartAppExecuting = false;
          } 
          else 
          {
            this.injectTestCardExecuting = false;
          }
        });
  } 
  }
}
</script>
